import { useEffect, useState } from 'react'
import { UnityContext } from 'react-unity-webgl'

interface UnityMessageReceiver {
  gameObject: string
  methods: UnityWalletMethods
}

interface UnityWalletMethods {
  setPublicKey: string
  setNfts: string
}

function useUnityWallet(unityContext: UnityContext) {
  const [receiver, setReceiver] = useState<UnityMessageReceiver | null>(null)

  useEffect(() => {
    unityContext.on('UnityWalletReady', function (gameObject: string, methodsJson: string) {
      if (gameObject && methodsJson) {
        setReceiver({ gameObject, methods: JSON.parse(methodsJson) })
      }
    })
  })

  return { receiver }
}

export { useUnityWallet }
