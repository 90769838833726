import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import * as anchor from '@project-serum/anchor'

export const network: WalletAdapterNetwork = WalletAdapterNetwork.Mainnet

export function getRpcEndpoint() {
  if (network === WalletAdapterNetwork.Mainnet) {
    return 'https://api.mainnet-beta.solana.com'
  }
  return 'https://api.devnet.solana.com'
}

export const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s')
