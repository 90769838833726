import { useWallet } from '@solana/wallet-adapter-react'
import * as anchor from '@project-serum/anchor'
import { useEffect, useState } from 'react'
import { getRpcEndpoint, TOKEN_METADATA_PROGRAM_ID } from '../solana-consts'
import { PublicKey } from '@solana/web3.js'
import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import _ from 'lodash'
import axios from 'axios'
import { programs } from '@metaplex/js'
const {
  metadata: { Metadata },
} = programs

interface NftData {
  mint: string
  category: string
  image: string
  animation: string
  attributes: NftAttribute[]
  creators: NftCreator[]
}

interface NftAttribute {
  trait_type: string
  value: string
}

interface NftCreator {
  address: string
  share: number
}

const connection = new anchor.web3.Connection(getRpcEndpoint())

function useNftList() {
  const { publicKey } = useWallet()

  const [nfts, setNfts] = useState<Array<NftData>>([])

  async function fetchNft() {
    let nftsInWallet: NftData[] = []
    if (publicKey) {
      nftsInWallet = await getNftsInWallet(connection, publicKey)
    }
    setNfts(nftsInWallet)
  }

  useEffect(() => {
    fetchNft()
  }, [publicKey])

  return { nfts }
}

async function getNftsInWallet(connection: anchor.web3.Connection, owner: PublicKey): Promise<NftData[]> {
  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(owner, {
    programId: TOKEN_PROGRAM_ID,
  })

  const nfts: string[] = []

  for (let index = 0; index < tokenAccounts.value.length; ++index) {
    const tokenAccount = tokenAccounts.value[index]
    const tokenAmount = tokenAccount.account.data.parsed.info.tokenAmount

    if (tokenAmount.amount === '1' && tokenAmount.decimals === 0) {
      nfts.push(tokenAccount.account.data.parsed.info.mint)
    }
  }

  let promises = _.map(nfts, (nft) => {
    return getMetadataForNft(connection, nft, owner)
  })

  return Promise.all(promises).then((nfts) => {
    return _.filter(nfts, (nft) => {
      return !!nft.mint
    })
  })
}

async function getMetadataForNft(
  connection: anchor.web3.Connection,
  nftKey: string,
  owner: PublicKey
): Promise<NftData> {
  let nftData: NftData = {} as NftData

  try {
    let [accountKey] = await anchor.web3.PublicKey.findProgramAddress(
      [Buffer.from('metadata'), TOKEN_METADATA_PROGRAM_ID.toBuffer(), new anchor.web3.PublicKey(nftKey).toBuffer()],
      TOKEN_METADATA_PROGRAM_ID
    )

    const accountInfo: any = await connection.getParsedAccountInfo(accountKey)
    const metadata = new Metadata(owner.toString(), accountInfo.value)
    const { data } = await axios.get(metadata.data.data.uri)

    nftData.mint = metadata.data.mint
    nftData.creators = metadata.data.data.creators as any[]
    nftData.animation = data.animation_url
    nftData.image = data.image
    nftData.attributes = data.attributes
    return nftData
  } catch (error) {
    console.error(`Error getting nft data for ${nftKey}: ${error}`)
    return nftData
  }
}

export { useNftList }
