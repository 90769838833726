import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import React, { useEffect } from 'react'
import Unity, { UnityContext } from 'react-unity-webgl'
import { useNftList } from './hooks/solana-nft'
import { useUnityWallet } from './hooks/unity-wallet'

const gameName = 'build'
const gamePath = 'build/Build'

const gzipContext = {
  loaderUrl: `${gamePath}/${gameName}.loader.js`,
  dataUrl: `${gamePath}/${gameName}.data.gz`,
  frameworkUrl: `${gamePath}/${gameName}.framework.js.gz`,
  codeUrl: `${gamePath}/${gameName}.wasm.gz`,
}

const devContext = {
  loaderUrl: `${gamePath}/${gameName}.loader.js`,
  dataUrl: `${gamePath}/${gameName}.data`,
  frameworkUrl: `${gamePath}/${gameName}.framework.js`,
  codeUrl: `${gamePath}/${gameName}.wasm`,
}

const unityContext = new UnityContext(devContext)

function UnityGame() {
  const { publicKey, disconnect: disconnectWallet } = useWallet()
  const { receiver } = useUnityWallet(unityContext)
  const { setVisible: setWalletModalVisible } = useWalletModal()
  const { nfts } = useNftList()

  useEffect(function () {
    unityContext.on('quitted', function () {})
  }, [])

  useEffect(function () {
    unityContext.on('SelectWallet', function () {
      setWalletModalVisible(true)
    })

    unityContext.on('DisconnectWallet', function () {
      disconnectWallet()
    })
  })

  useEffect(
    function () {
      if (receiver) {
        const key = publicKey ? publicKey.toString() : ''
        unityContext.send(receiver.gameObject, receiver.methods.setPublicKey, key)
      }
    },
    [receiver, publicKey]
  )

  useEffect(
    function () {
      if (receiver) {
        const json = JSON.stringify(nfts)
        unityContext.send(receiver.gameObject, receiver.methods.setNfts, json)
      }
    },
    [receiver, nfts]
  )
  return <Unity unityContext={unityContext} style={{ height: 600, width: 960 }} />
}

export { UnityGame }
